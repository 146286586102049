import { get, post, fileUpload, del } from './Methods'


export const codigo = (email) => post('/autorizacion/codigo', { email })
export const login = (email, codigo) => post('/autorizacion', { email, codigo })
export const logout = () => del('/autorizacion')

export const fetchBarriles = () => get('/api/barriles')
export const enviarBarriles = (body) => fileUpload('/api/barriles/batch', body)

export const fetchTablas = () => get('/api/tablas')