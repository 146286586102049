import { combineReducers } from "redux";
import createReducer from "./createReducer";
import { FIJAR_SESSION, ELIMINAR_SESSION, FIJAR_TABLAS } from "../actions/Types";

const initialSession = {
  token: '',
  email: ''
}

const session = createReducer(initialSession, {
  [FIJAR_SESSION]: (state, { token, email, nombre_empresa }) => ({ token, email, nombre_empresa }),
  [ELIMINAR_SESSION]: () => ({ ...initialSession })
})

const tablas = createReducer([], {
  [FIJAR_TABLAS]: (state, { tablas }) => [ ...tablas ]
})


export default combineReducers({
  session, tablas
})