import React, { Component } from 'react';
import { connect } from 'react-redux';

import { enviarBarriles } from "../api";
import { eliminarSession } from "../actions";
import { bindActionCreators } from 'redux';
import { isEmpty } from '../utils';


class Carga extends Component {
  state = {
    message: '',
    error: '',
    busy: false
  }

  submit = () => {
    const body = new FormData()
    const selectedFile = document.getElementById('barriles').files[0];
    body.append('barriles', selectedFile)
    this.clear()
    this.busy()
    enviarBarriles(body).then(res => {
      if (res.status === 'success') {
        this.setState({ message: 'Barriles ingresados: ' + res.data.length })
      } else {
        this.error(res.data)
      }
    }).catch(e => this.error(e.message)).finally(this.busy)
  }
  
  error = (message) => this.setState({ error: message })
  clear = () => this.setState({ error: '', message: '' })
  busy = () => this.setState({ busy: !this.state.busy })

  render() {
    const disabled = this.state.busy
    return (
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <div className="card mt-4">
              <div className="card-body">
                <div>
                  <Message value={this.state.message} onClick={this.clear} />
                  <Message value={this.state.error} danger onClick={this.clear} />
                </div>
                <div className="form-group">
                  <label>Indica el archivo excel a cargar</label>
                  <input id="barriles" type="file" className="form-control-file" />
                </div>
                <button className="btn btn-primary btn-block" type="button" onClick={this.submit} disabled={disabled}>
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Message = ({ value, danger, onClick }) => !isEmpty(value) && (
  <div className={"alert " + (danger ? "alert-danger" : "alert-success")}>
    <strong>{value}</strong>
    <button type="button" className="close" aria-label="Close" onClick={onClick}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
)

const mapStateToProps = ({ session }) => ({ email: session.email })
const mapDispatchToProps = (dispatch) => bindActionCreators({
  eliminarSession
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Carga);
