import React from 'react';

import { Login, Dashboard, Carga } from "./screens";
import { connect } from 'react-redux';
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { isEmpty } from './utils';
import { CARGA } from './utils/Routes';

import './App.css'
import Header from './components/Header';

function App({ autorizado }) {
  if (!autorizado) {
    return <Login />
  }

  return (
    <Router>
      <Route component={Header} />
      <Switch>
        <Route path={CARGA} component={Carga} />
        <Route component={Dashboard} />
      </Switch>
    </Router>
  )
}

const mapStateToProps = ({ session }) => ({
  autorizado: !isEmpty(session.token)
})

export default connect(mapStateToProps)(App);
