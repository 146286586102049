import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from "react-router-dom";

import { eliminarSession } from "../actions";
import { DASHBOARD, CARGA } from '../utils/Routes';


const Header = ({ eliminarSession, email, nombre_empresa, location }) => (
  <nav className="navbar navbar-expand-lg navbar-dark cs-primary-bg">
    <a className="navbar-brand" href="#">{nombre_empresa}</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav mr-auto">
        <li className={"nav-item" + activo(location.pathname, DASHBOARD)}>
          <Link className="nav-link" to={DASHBOARD}>Dashboard</Link>
        </li>
        <li className={"nav-item" + activo(location.pathname, CARGA)}>
          <Link className="nav-link" to={CARGA}>Carga</Link>
        </li>
      </ul>
      <div>
        <span className="navbar-text">{email}</span>
        <button className="btn btn-link text-white" type="button" onClick={eliminarSession}>Salir</button>
      </div>
    </div>
  </nav>
)

const activo = (pathname, route) => {
  console.log(pathname)
  return pathname === route ? ' active' : ''}

const mapStateToProps = ({ session }) => ({ email: session.email, nombre_empresa: session.nombre_empresa })
const mapDispatchToProps = (dispatch) => bindActionCreators({ eliminarSession }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)