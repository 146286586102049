import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "qrcode";
import { saveAs } from 'file-saver';
import JSZip from 'jszip'

import { fetchBarriles } from '../api';
import { obtenerTablas } from "../actions";
import { bindActionCreators } from 'redux';
import { formatFechaHora, kegtrackId } from '../utils';


function toggleModal() {
  window.$('#qrModal').modal('toggle')
}


async function generarQr(id) {
  const canvas = await QRCode.toCanvas(document.getElementById("canvas_qr"), kegtrackId(id), { width: 512 })
  const context = canvas.getContext('2d')
  context.font = '30px System'
  context.textAlign = 'center'
  context.fillText('Kegtrack', canvas.width / 2, canvas.height * 0.1)
  context.fillText('ID Barril: ' + id, canvas.width / 2, canvas.height * 0.95)
  context.strokeRect(10, 10, canvas.width - 20, canvas.height - 20)
  //return canvas.toDataURL("image/png")
  return canvas
}


class Dashboard extends Component {
  state = {
    ids: [],
    barriles: [],
    checkall: false,
    message: '',
    error: '',
    qr: '',
    id: '',
    busy: false
  }

  componentDidMount() {
    if (this.props.tablas.length === 0) {
      this.props.obtenerTablas().then(this.fetch)
    } else {
      this.fetch()
    }
  }

  verQr = async (id) => {
    /*QRCode.toDataURL(kegtrackId(id), { errorCorrectionLevel: 'H', width: 512 }, (err, url) => {
      if (err) throw err
      this.setState({ qr: url, id })
      //var container = document.getElementById('qr')
      //container.appendChild(canvas)
      toggleModal()
    })*/
    await generarQr(id)

    toggleModal()
  }

  descargarQRs = async () => {
    this.busy()
    const ids = this.state.ids

    var zip = new JSZip()
    for (let id of ids) {
      const canvas = await generarQr(id)
      await new Promise((res) => {
        canvas.toBlob(blob => {
          zip.file(id + ".png", blob)
          res()
        } ,'image/png', 1)
      })
    }

    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "qrs.zip");
      this.busy()
    });
  }

  fetch = () => fetchBarriles().then(res => {
    if (res.status === 'success') {
      const tablas = this.props.tablas
      this.setState({ barriles: res.data.map(v => {
        const capacidad = tablas.find(k => k.id === v.capacidad)
        const formato = tablas.find(k => k.id === v.formato)
        const pinchador = tablas.find(k => k.id === v.pinchador)

        return {
          ...v,
          capacidad: capacidad && capacidad.label,
          formato: formato && formato.label,
          pinchador: pinchador && pinchador.label
        }
      }) })
    }
  })

  onCheck = (id) => {
    if (this.state.ids.find(v => v === id)) {
      this.setState({ ids: this.state.ids.filter(v => v !== id)})
    } else {
      this.setState({ ids: [ ...this.state.ids, id]})
    }
  }

  onCheckAll = () => {
    if (!this.state.checkall) {
      console.log('checkall')
      this.setState({ ids: this.state.barriles.map(v => v.id)})
    } else {
      this.setState({ ids: [] })
    }
    this.setState({ checkall: !this.state.checkall })
  }
  
  error = (message) => this.setState({ error: message })
  clear = () => this.setState({ error: '', message: '' })
  busy = () => this.setState({ busy: !this.state.busy })

  render() {
    const { ids, checkall, busy } = this.state

    return (
      <div className="container">
        <div className="card mt-4">
          <div className="card-body">
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 8}}>
              <h4>Barriles</h4>
              <button type="button" className="btn btn-primary" onClick={this.descargarQRs} disabled={ids.length === 0 || busy}>
                {busy ? <Loading />: 'Descargar QRs'}
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}><input type="checkbox" checked={checkall} onChange={this.onCheckAll} /></th>
                  <th>id</th>
                  <th>capacidad</th>
                  <th>formato</th>
                  <th>pinchador</th>
                  <th>fecha</th>
                </tr>
              </thead>
              <tbody>
                {this.state.barriles.sort(sortId).map(value => <Row key={value.id} {...value} ver={this.verQr} ids={ids} onCheck={this.onCheck} />)}
              </tbody>
            </table>
          </div>
        </div>
        <QrModal qr={this.state.qr} id={this.state.id} />
      </div>
    );
  }
}

const Row = ({ id, formato, capacidad, pinchador, momento, ver, ids, onCheck }) => (
  <tr>
    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><input type="checkbox" checked={ids.find(v => v === id) || false} onChange={() => onCheck(id)} /></td>
    <td><button type="button" className="btn btn-link" onClick={() => ver(id)}>{id}</button></td>
    <td>{capacidad}</td>
    <td>{formato}</td>
    <td>{pinchador}</td>
    <td>{formatFechaHora(momento)}</td>
  </tr>
)

const QrModal = ({ qr, id }) => (
  <div className="modal fade" id="qrModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Barril {id}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          {/*<img src={qr} alt="qr" className="img-fluid" />*/}
          <canvas id="canvas_qr" />
        </div>
      </div>
    </div>
  </div>
)

const Loading = () => (
  <div className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </div>
)

function sortId(a, b) {
  return b.id - a.id
}

const mapStateToProps = ({ tablas }) => ({ tablas })
const mapDispatchToProps = (dispatch) => bindActionCreators({
  obtenerTablas
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
