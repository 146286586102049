import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { codigo } from '../api';
import { isEmpty, isEmail } from '../utils';
import { crearSession } from "../actions";


class Login extends Component {
  state =  {
    email: '',
    codigo: '',
    error: '',
    step: 0,
    busy: false
  }

  _busy = () => this.setState({ busy: !this.state.busy })
  error = (error) => this.setState({ error: error.toString() })
  clear = () => this.error('')

  enviarCorreo = (e) => {
    e.preventDefault()
    this._busy()
    this.clear()
    
    codigo(this.state.email.trim().toLowerCase()).then(res => {
      if (res.status === 'success') {
        this.codigoStep()
      } else {
        this.error(res.data)
      }
    }).catch(this.error).finally(this._busy)
  }

  submit = (e) => {
    e.preventDefault()
    this.clear()
    this._busy()
    this.props.crearSession(
      this.state.email.trim().toLowerCase(),
      parseInt(this.state.codigo, 10)
    ).then(res => {
      if (res.status !== 'success') {
        this.error(res.data)
      }
    }).catch(this.error).finally(this._busy)
  }

  emailStep = () => this.setState({ step: 0 })
  codigoStep = () => this.setState({ step: 1 })
  onChange = ({ target }) => this.setState({ [target.id]: target.value })

  render() {
    const { email, codigo, error, step } = this.state

    return (
      <div className="cs-login cs-primary-bg">
        <div className="container">
          <div className="row justify-content-md-center">
            <form className="col-md-6" onSubmit={step === 0 ? this.enviarCorreo : this.submit}>
              <div className="text-center mt-5 mb-5">
                <img src="splash_icon.png" className="img-fluid" alt="kegtrack" />
              </div>
              <ErrorMessage message={error} />
              {step === 0 ? 
                <EmailForm email={email} onChange={this.onChange} /> :
                <CodigoForm codigo={codigo} email={email} onChange={this.onChange} volver={this.emailStep} />}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const ErrorMessage = ({ message }) =>  !isEmpty(message) && (
  <div className="alert alert-danger">
    {message}
  </div>
)

const EmailForm = ({ email, onChange }) => (
  <div className="card">
    <div className="card-body">
      <div className="form-group">
        <label>Correo</label>
        <input className="form-control" placeholder="correo@email.com" id="email" value={email} onChange={onChange} />
      </div>
      <button className="btn btn-primary btn-block" disabled={!isEmail(email)}>Enviar código</button>
    </div>
  </div>
)

const CodigoForm = ({ email, codigo, onChange, volver }) => (
  <div className="card">
    <div className="card-body">
      <div className="form-group">
        <label>Codigo</label>
        <input className="form-control" value={codigo} placeholder="123456" type="number" id="codigo" onChange={onChange} />
        <small>*Busca el codigo en tu correo {email}</small>
      </div>
      <button className="btn btn-primary btn-block" disabled={isEmpty(codigo)}>Ingresar</button>
      <button className="btn btn-link btn-block" type="button" onClick={volver}>Volver</button>
    </div>
  </div>
)

const mapDispatchToProps = (dispatch) => bindActionCreators({
  crearSession
}, dispatch)

export default connect(null, mapDispatchToProps)(Login);
