import { version } from "../package.json";

let host = 'https://kegtrack.clan28.cl'

if (process.env.NODE_ENV === 'development') {
  host = 'http://localhost:8000'
}

export {
  version, host
}