import moment from 'moment'
import 'moment/locale/es'

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
const $ = window.$;

export function toggleModal(id) {
  $('#' + id).modal('toggle')
}

export function isEmpty(s) {
  return !s || s === null || (typeof s === 'string' ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function str(v) {
  return v && v !== null && typeof s === 'string' ? v.toString() : ''
}

export function isEmail(email) {
  return EMAIL_REG.test(email);
}

export function formatFechaHora(fecha) {
  return moment(fecha).format('DD/MM/YYYY - HH:mm')
}

export function kegtrackId(id) {
  return 'kegtrack;ID' + id
}