import { FIJAR_SESSION, ELIMINAR_SESSION, FIJAR_TABLAS } from "./Types"
import { login, logout, fetchEmpresas, fetchTablas } from "../api"

export function crearSession(email, codigo) {
  return (dispatch) => login(email, codigo).then(res => {
    if (res.status === 'success') {
      dispatch({
        type: FIJAR_SESSION,
        ...res.data
      })
    }
    return res
  })
}

export function eliminarSession() {
  return (dispatch) => logout().finally(() => dispatch({
    type: ELIMINAR_SESSION
  }))
}

export function obtenerTablas() {
  return (dispatch) => fetchTablas().then(res => {
    if (res.status === 'success') {
      dispatch({
        type: FIJAR_TABLAS,
        tablas: res.data
      })
    }
  })
}