import { store } from '../Store'
import { host } from "../config";


export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

function request(path, method, body) {
  const { token } = store.getState().session
  return fetch(host + path, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'TOKEN': token
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(r => r.json())
}

export function fileUpload(path, body) {
  const { token } = store.getState().session
  return fetch(host + path, {
    headers: {
      'TOKEN': token
    },
    method: 'POST',
    body
  }).then(r => r.json())
}